<template>
    <div>
        <header-portrate />


    <div style="margin-bottom:70px;">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />

        <v-row class="mainrow m-2" :style="`background:#d7b10015;direction:`+lang.dir+`;margin-top:100px !important;border:1px solid #555;border-radius:10px`">
            <v-col cols="12" md="12" sm="12" class="text-center backRed">
                {{ display_message }}
            </v-col>
            <v-col cols="12" md="12" sm="12" class="text-center">
                <v-row>
                    <v-col cols="12" md="3" sm="12"></v-col>
                    <v-col cols="12" md="2" sm="12">{{ lang.en_company_name}}</v-col>
                    <v-col cols="12" md="4" sm="12"><b-form-input class="inborder" v-model="zatca.company_name" /></v-col>
                    <v-col cols="12" md="3" sm="12"></v-col>

                    <v-col cols="12" md="3" sm="12"></v-col>
                    <v-col cols="12" md="2" sm="12">{{ lang.vat_number}}</v-col>
                    <v-col cols="12" md="2" sm="12"><b-form-input class="inborder" v-model="zatca.vat_number" /></v-col>
            
                    <v-col cols="12" md="1" sm="12">{{ lang.crt_number}}</v-col>
                    <v-col cols="12" md="1" sm="12"><b-form-input class="inborder" v-model="zatca.crt_number" /></v-col>
                    <v-col cols="12" md="3" sm="12"></v-col>

                    <v-col cols="12" md="3" sm="12"></v-col>
                    <v-col cols="12" md="2" sm="12">{{ lang.en_address}}</v-col>
                    <v-col cols="12" md="4" sm="12"><b-form-input class="inborder" v-model="zatca.address" /></v-col>
                    <v-col cols="12" md="3" sm="12"></v-col>

                    <v-col cols="12" md="3" sm="12"></v-col>
                    <v-col cols="12" md="2" sm="12">{{ lang.integration_type}}</v-col>
                    <v-col cols="12" md="2" sm="12"><b-form-select class="selborder" :options="zatca_type" v-model="zatca.zatca_type" /></v-col>
            
                    <v-col cols="12" md="1" sm="12">{{ lang.active_zatca}}</v-col>
                    <v-col cols="12" md="1" sm="12"><b-form-select class="selborder" :options="active_zatca" v-model="zatca.active_zatca" /></v-col>
                    <v-col cols="12" md="3" sm="12"></v-col>
                    

                    <v-col cols="12" md="3" sm="12" class="text-center backRed">
                        {{ lang.national_address }}
                    </v-col>
                    <v-col cols="12" md="3" sm="12"></v-col>

                    <v-col cols="12" md="3" sm="12"></v-col>
                    <v-col cols="12" md="2" sm="12">{{ lang.integration_type}}</v-col>
                    <v-col cols="12" md="2" sm="12"><b-form-select class="selborder" :options="zatca_type" v-model="zatca.zatca_type" /></v-col>
            
                    <v-col cols="12" md="1" sm="12">{{ lang.active_zatca}}</v-col>
                    <v-col cols="12" md="1" sm="12"><b-form-select class="selborder" :options="active_zatca" v-model="zatca.active_zatca" /></v-col>
                    <v-col cols="12" md="3" sm="12"></v-col>
                    
                </v-row>
            </v-col>
            

            <v-col cols="12" md="12" sm="12" class="text-end" style="padding:5px">
                <v-btn style="background:green;color:#FFF;margin-bottom:30px;" @click="updateSettings()">{{lang.save}}</v-btn>
            </v-col>
        </v-row>
        <div>
            
        </div>
         
        
        
        <vue-snotify></vue-snotify>
    </div>
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
export default{
    components: {BreadCrumbs, TabsComp, HeaderPortrate, Footer},
    data() {
        return {
            currentFile: undefined,
            progress: 0,
            active_tab:0,
            message: "",
            zatca: {
                company_name: '',
                vat_number: '',
                crt_number: '',
                zatca_type: 0,
                address: '',
                active_zatca: 0,
            },
            settings:{
                site_title: '',
                company_name: '',
                company_name_en: '',
                vat_number: '',
                logo: '',
                address: '',
                tafweed: '',
                calendar: '',
                terms: '',
                crt_number: '',
                mobile: '',
                address_en: '',
                logo_img: [],
                whatsapp: [],
            },
            attached: []
        }
    },
    methods: {
        selectFiles(){
            // const files = this.$refs.attached.files;
            // console.log(files);
            // this.settings.logo_img = [...this.settings.logo_img,...files];
            const files = this.$refs.attached.files;
            this.attached = [...this.attached, ...files];
        },
        getSettings()
        {
            const post = new FormData();
            post.append("type",'getSettings');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        },
        updateSettings(e){
            // console.log(this.settings.logo_img)
            const post = new FormData();
            post.append("type",'updateSettings');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[site_title]",this.settings.site_title);
            post.append("data[company_name]",this.settings.company_name);
            post.append("data[calendar]",this.settings.calendar);
            post.append("data[vat_number]",this.settings.vat_number);
            post.append("data[logo]", typeof this.attached[0] !== 'undefined' ? this.attached[0] : this.settings.logo);
            post.append("data[address]",this.settings.address);
            post.append("data[tafweed]",this.settings.tafweed);
            post.append("data[terms]",this.settings.terms);
            post.append("data[company_name_en]",this.settings.company_name_en);
            post.append("data[address_en]",this.settings.address_en);
            post.append("data[crt_number]",this.settings.crt_number);
            post.append("data[mobile]",this.settings.mobile);
            post.append("data[whatsapp]",this.settings.whatsapp);

            axios.post(
                this.$SAMCOTEC.r_path,post,{
                    headers: {
                        "Content-Type": "multipart/form-data"
                    },
                }
            ).then(
                (response) => {
                    const res = response.data;
                    // console.log("hello",res);
                    if (this.$snotify.notifications.length > 0) {
                        this.$snotify.notifications.forEach(notification => {
                            this.$snotify.remove(notification.id)
                        });
                    }
                    this.settings = res.results.data;
                    this.$store.state.settings = res.results.data;
                    this.$snotify.success('تم حفظ الاعدادات ..\nيرجى اخذ العلم انه اذا قمت بتغيير الرقم الضريبي سيتوجب عليك اعادة تفعيل النسخة', {
                        timeout: 5000,
                        showProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            )
        }
    },
    
    created() {
        this.getSettings();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        zatca_type: function()
        {
            return [
                {text: 'انشاء شهادة فقط', value: 0},
                {text: 'الربط التجريبي', value: 1},
                {text: 'الربط النهائي', value: 2},
            ]
        },
        active_zatca: function()
        {
            return [
                {text: 'غير مفعل', value: 0},
                {text: 'مفعل للتجريبي', value: 1},
                {text: 'مفعل للربط النهائي', value: 1},
            ]
        },
        display_message: function() {
            if(this.zatca_status == 1) return this.lang.zatca_integrated_message;
            if(this.zatca_status == 0) return this.lang.zatca_not_integrated_message;
            else return this.lang.zatca_not_integrated_message
        },
        items: function() {
            return {
                    text: this.lang.system_settings,
                    disabled: true,
                    to: '/settings/system-settings',
                }
        },
        tabs: function() {
            const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
        }
    },
}
</script>